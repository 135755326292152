import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "autobackup-api"
    }}>{`AutoBackup API`}</h1>
    <p>{`AutoBackup provides `}<a parentName="p" {...{
        "href": "https://graphql.org/learn/"
      }}>{`GraphQL API`}</a>{` for any kind of automation. To interact with the
API you need to generate an API key and provide it in the `}<inlineCode parentName="p">{`x-api-key`}</inlineCode>{` header.
Path to the endpoint: `}<a parentName="p" {...{
        "href": "https://api.backup.nordcloudapp.com/graphql"
      }}>{`https://api.backup.nordcloudapp.com/graphql`}</a></p>
    <p>{`The API allows to:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`register an account,`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`trigger a scan on registered accounts and check the scan’s status,`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`list resources,`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`create, remove and list backup policies,`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`create, remove, update and list backup setups,`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`attach backup setups and policies to resources,`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`enable backup on resources.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "how-to-make-a-call"
    }}>{`How to make a call`}</h2>
    <p>{`You can use cURL or GraphQL client to make a call for data. Below is the example of the query using cURL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl --location --request POST 'https://api.backup.nordcloudapp.com/graphql' \\
--header 'x-api-key: api-key' \\
--header 'Content-Type: application/json' \\
--data-raw '{"query": "query acc {accounts(searchCriteria: {customerId: \\"customerid\\"}) {data {id }}}"}'
`}</code></pre>
    <p>{`Basically, in case of cURL - you have to put your query into a JSON:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
   "query": "...query..."
}
`}</code></pre>
    <h2 {...{
      "id": "how-to-get-the-api-schema"
    }}>{`How to get the API schema`}</h2>
    <p>{`As any GraphQL API, you can introspect the schema using several available tools.
For example, `}<a parentName="p" {...{
        "href": "https://github.com/graphql/graphql-playground"
      }}>{`GraphQL playground`}</a>{` offers schema introspection.
For authentication, use the API key.`}</p>
    <h2 {...{
      "id": "example-of-processes"
    }}>{`Example of processes`}</h2>
    <h3 {...{
      "id": "add-new-aws-cloud-account"
    }}>{`Add new AWS cloud account`}</h3>
    <p>{`In order to add new cloud account, first, you have to upload role or use the existing role.
You can get the role cloudformation template by query `}<inlineCode parentName="p">{`awsAccountAccessRoleTemplate`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`query roleTemplate {
  awsAccountAccessRoleTemplate(input: {
    customerId: "customerId",
    accountId: "AWS Account ID",
    accountType: PROTECTED
  })
}
`}</code></pre>
    <p>{`Where you have to fill the following values:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`customerId`}</inlineCode>{` is unique ID of the customer in the AutoBackup`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`accountId`}</inlineCode>{` is the numeric value of AWS account which you want to add to AutoBackup`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`accountType`}</inlineCode>{` can be `}<em parentName="p">{`PROTECTED`}</em>{` or `}<em parentName="p">{`VAULT`}</em></p>
      </li>
    </ul>
    <p>{`In response, you will get:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "data": {
        "awsAccountAccessRoleTemplate": "https://nc-backup-templates-bucket-production.s3-eu-west-1.amazonaws.com/generated-templates/role-customerid-hash.yml"
    }
}
`}</code></pre>
    <p>{`If you applied the template then you can add the account to the AutoBackup`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation addAccount {
  createAccountAws(input: {
    customerId: "customerId",
    number: "aws account id",
    name: "The account name",
    accountType: PROTECTED
  }) {
    accountId
  }
}
`}</code></pre>
    <p>{`If everything is correct then you will get in response the account id.`}</p>
    <h3 {...{
      "id": "scan-account"
    }}>{`Scan account`}</h3>
    <p>{`To scan a single account you can use the following mutation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation scanAccount {
  triggerScanOnCloudAccount(input: {
    customerId: "customerId",
    accountId: "accountId"
  }) {
    scanId
  }
}
`}</code></pre>
    <p>{`To get the scan status:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`query scanStatus {
  scanStatusAccount(input: {customerId: "customerId", accountId: "accountId"}) {
    scanId
    status
  }
}
`}</code></pre>
    <h3 {...{
      "id": "attach-the-backup-policy-and-setup-to-the-resource"
    }}>{`Attach the backup policy and setup to the resource`}</h3>
    <p>{`The backup policy and/or setups can be attached in two ways.
Either for the specific list of resources, given in `}<inlineCode parentName="p">{`resourceIDs`}</inlineCode>{` attribute or to resources selected by the filter defined in `}<inlineCode parentName="p">{`filters`}</inlineCode>{`.
The last property is identical to the one passed to `}<inlineCode parentName="p">{`resource`}</inlineCode>{` query.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation attachBackupPolicySetup {
  changeBackupPolicyAndSetups(input: {
    customerId: "customerId",
    backupPolicyId: "policy-id-1",
    setupsIds: ["setup-id-1", "setup-id-2"],
    filters: [
      {id: "RESOURCE_TYPE", values: ["aws-ebs", "aws-s3"]}
    ]
  })
}
`}</code></pre>
    <p>{`In the above example, you filter your resources to EBS and S3 and attach to all of them a policy with id `}<inlineCode parentName="p">{`policy-id-1`}</inlineCode>{` and setups `}<inlineCode parentName="p">{`setup-id-1`}</inlineCode>{`, `}<inlineCode parentName="p">{`setup-id-2`}</inlineCode>{`.
The system, based on the resource type for which the setup is defined, will choose the appropriate setup for the filtered resources.`}</p>
    <h3 {...{
      "id": "enabledisable-backup"
    }}>{`Enable/disable backup`}</h3>
    <p>{`The backup can be enabled, or disabled, similarly as you attach backup policy and setups.
Basically, you can either change backup state for the specific list of resources or to the resources defined by the filters.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation changeBackupSetup {
  changeBackupState(input: {
    customerId: "customerId",
    resourceIDs: ["res-1", "res-2"],
    state: true
  })
}
`}</code></pre>
    <p>{`By running the above example, you will enable backup for resources `}<inlineCode parentName="p">{`res-1`}</inlineCode>{` and `}<inlineCode parentName="p">{`res-2`}</inlineCode>{`.
To disable the backup you have to change the state property to `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      